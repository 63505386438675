<template>
  <div class="xl:flex xl:flex-col 2xl:flex-row grid grid-cols-1 w-full h-full bg-[#F6F6F6] customer-saying" v-lazy-background data-bg="/customer-saying-bg.webp">
    <div
      class="text-neutral-900 lg:text-6xl md:text-4xl text-3xl font-medium font-['Montserrat'] lg:leading-[5rem] leading-[1.3em] w-full text-center mt-10 md:mt-24"
    >
      <div class="lg:w-11/12 w-9/12 mx-auto">
        What Our Customers Are Saying About Us
      </div>
    </div>
    <CommonVerticalCarousel :items="items" :items-to-show="itemsToShow" :auto-play="2000" class="sm:mt-20 mt-5">
      <template #default="{ item }">
        <div class="h-full items-center justify-center rounded-[20px] shadow backdrop-blur-lg w-9/12 mx-auto bg-white flex flex-col lg:p-5 p-4 overflow-auto">
          <p class="sm:text-xl text-sm sm:font-medium font-['Montserrat'] sm:leading-[30px] w-full">
            “{{ item.content }}”
          </p>
          <div class="flex items-center w-full mt-3">
            <div class="flex flex-col">
              <p class="font-bold">
                {{ item.name }}
              </p>
              <p class="text-gray-600">
                {{ item.type }} Review
              </p>
            </div>
          </div>
        </div>
      </template>
    </CommonVerticalCarousel>
  </div>
</template>

<script setup>
const items = [
  {
    content: 'Chris and his team went above and beyond. He proactively communicated with my insurance provider and even took several calls with me when they were giving me a hard time on coverage. He helped me fight for my claim. He walked me through the process, thoroughly informed me on inspections, and made recommendations on what my roof might need. Working with Canopy Home Exteriors was the best part of the whole process.',
    name: 'Teo Harrisson',
    type: 'Google'
  },
  {
    content: 'Calvin with Canopy Home Exteriors did what other roofers could not. He got my roof insurance claim approved after an initial denial with a separate roofing company. He made the process super easy by speaking to the insurance adjuster for me and handling all of the hard work. Front office staff is just as helpful and knowledgeable with the overall roofing industry and insurance claims. I\'m very glad I gave them a chance to assess my roof. Will definitely use them again for my other properties!',
    name: 'K. Nix',
    type: 'Google'
  },
  {
    content: 'Hariss was the best! We could see some missing shingles on our roof & knew there was some damage from a previous storm so wanted someone to take a look at it that we know we could trust. Hariss got us on the schedule, came out quickly, and explained the entire process to us! He is super knowledgeable & I’m looking forward to continuing this process with him & Canopy Home Exteriors! Would recommend for any roofing needs!',
    name: 'Taylor Hosemann',
    type: 'Google'
  },
  {
    content: 'My insurance company sent me a non renewal and Canopy Home Exteriors came out to inspect the roof and had my claim in and approval the same day. Canopy Home Exteriors had me on their schedule within a week for the repairs and it was completed in one day. Everything was explained to be throughout the process. Any questions I had I was able to ask and get answers in a timely manner. I definitely recommend Canopy Home Exteriors to anyone who needs an inspection or repairs.',
    name: 'Jasmine Cooper',
    type: 'Google'
  },
  {
    content: 'No one wants to have to get a new roof, but it’s just one of those necessary things of owning a house. When taking on such a major project you need people you can trust.  Chris made the “sales” process easy & the install crew worked all day to make sure that our new roof done.  The CEO, Dan, even stopped by to check on the progress. These guys are trustworthy and do excellent work.',
    name: 'Noah Pines',
    type: 'Google'
  }
]
const { width } = useWindowSize()
const itemsToShow = computed(() => width.value <= 768 ? 1 : 2)
</script>

<style scoped>
.customer-saying {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
